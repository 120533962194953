import React from "react"
import styled from "styled-components"
import theme from "styles/theme"

const { colors } = theme

const DefaultBackground = () => <div />

const Container = ({ id, children, Background = DefaultBackground }) => (
  <section id={id} style={{ position: "relative", paddingTop: "4em" }}>
    <Background />
    <SectionContainer>{children}</SectionContainer>
  </section>
)

const Header = ({ name, icon = "", label = "" }) => (
  <h2>
    {name}
    {icon && (
      <span role="img" aria-label={label} style={{ marginLeft: "10px" }}>
        {icon}
      </span>
    )}
  </h2>
)

export default {
  Container,
  Header,
}

const SectionContainer = styled.div`
  min-width: 320px;
  //max-width: 1366px;
  color: ${colors.lightOcean};
  display: flex;
  margin: auto;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  padding: 1em 2em 0em 2em;
  scroll-behavior: smooth;

  @media only screen and (max-width: 800px) {
    padding: 1em 1em 0em 1em;
  }
`
