import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Section from "src/components/common/Section"
import Article from "src/components/Article"
import { CardContainer } from "src/components/common/Card"

const Articles = () => {
  const data = useStaticQuery(graphql`
    {
      articles: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/articles/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              showcase
              external
              github
              image
              description
              tags
              date
              time
            }
            html
          }
        }
      }
    }
  `)
  const articles = data.articles.edges.filter(
    ({ node }) => node.frontmatter.showcase === "true"
  )
  console.log(articles)
  return (
    <Section.Container id="articles">
      <Section.Header name="Articles" icon="✍️" label="writing" />
      <CardContainer minWidth="350px">
        {articles.map(({ node }, i) => {
          const { frontmatter } = node
          const {
            github,
            external,
            title,
            tags,
            description,
            image,
            date,
            time,
          } = frontmatter
          return (
            <Article
              key={i}
              title={title}
              text={description}
              url={external}
              repositoryUrl={github}
              tags={tags}
              image={image}
              date={date}
              time={time}
            />
          )
        })}
      </CardContainer>
    </Section.Container>
  )
}

export default Articles
