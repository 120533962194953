import React from "react"
import { Link } from "rebass"
import styled from "styled-components"
import FontAwesomeIcon from "react-fontawesome"

import theme from "../../styles/theme"

const { colors } = theme

const SocialLink = ({ fontAwesomeIcon, name, url, color }) => (
  <IconLink
    href={url}
    target="_blank"
    color={color}
    rel="noreferrer"
    aria-label={name}
  >
    <FontAwesomeIcon name={fontAwesomeIcon} />
  </IconLink>
)

export default SocialLink

const IconLink = styled(Link)`
  transition: color 0.5s;
  color: ${colors.primary};
  text-decoration: none;
  &:hover {
    color: ${colors.blue};
  }
`
