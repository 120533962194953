import React from "react"

import Layout from "src/components/layout"
import SEO from "src/components/seo"
import Projects from "sections/Projects"
import About from "sections/About"
import Articles from "sections/Articles"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="iSofer.io" />
      <About />
      <Projects />
      <Articles/>
    </Layout>
  )
}

export default IndexPage
