import styled from "styled-components"

export const Badge = styled.span`
  padding: 0.2rem 1rem;
  margin: 0px 0.2rem .2rem 0px;
  font-size: 0.7142em;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;
  background: ${({ bg }) => bg};
  color: ${({ color }) => color};
  border-radius: 0.875rem;
  transition: 0.3s all;
  opacity: 1;
  &:hover {
    opacity: 0.8;
    transition: 0.3s all;
  }
`

export default Badge
