import React from "react"
import { Box, Text } from "rebass"
import styled from "styled-components"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import Card from "src/components/common/Card"
import theme from "styles/theme"

const { colors } = theme

const CoverImage = styled.img`
  width: 100%;
  object-fit: cover;
`

const EllipsisHeading = styled(Text)`
  overflow: hidden;
  font-weight: 600;
  text-transform: uppercase;
  text-overflow: ellipsis;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  border-bottom: ${colors.primary} 5px solid;
`
const Timestamp = styled(Box)`
  background-color: rgb(124, 55, 173);
  color: white;
  position: absolute;
  display: inline;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  right: 0;
  bottom: 0;
  clip-path: polygon(0px 0%, 100% 0%, 100% 100%, 10% 100%);
  margin: 0px;
  padding: 10px 10px 10px 20px;
  border-radius: 0px 0px 8px;
`

const Article = ({ title, text, image, url, date, time }) => (
  <Card
    onClick={() =>
      window.open(url, "_blank") &&
      trackCustomEvent({ category: "articles", action: "click", label: title })
    }
    pb={4}
  >
    <EllipsisHeading m={3} p={1}>
      {title}
    </EllipsisHeading>
    {image && <CoverImage src={image} height="200px" alt={title} />}
    <Text m={3}>{text}</Text>
    <Timestamp>
      <span>{`${date} - ${Math.ceil(time)} min`}</span>
    </Timestamp>
  </Card>
)

export default Article
