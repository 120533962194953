import React from "react"
import Particles from "react-particles-js"

import Section from "src/components/common/Section"
import SocialLinks from "src/components/common/SocialLinks"

const About = () => {
  return (
    <Section.Container id="about">
      <Particles
        style={{ position: "absolute", paddingRight: "2em", paddingTop: "1em" }}
        params={{
          particles: {
            number: {
              value: 50,
            },
            size: {
              value: 3,
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
            },
          },
        }}
      />
      <h1>Hi</h1>
      <p>I'm Idan.</p>
      <p>Experienced Software Engineer, Serverless enthusiast, Cloud Architect, </p>
      <p>an Instagram Foodie and an amateur music producer.</p>
      <SocialLinks />
    </Section.Container>
  )
}

export default About
