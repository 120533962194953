import React from "react"
import { Text, Flex, Box } from "rebass"
import styled from "styled-components"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import Card from "./common/Card"
import ImageText from "./common/ImageText"
import Badge from "./common/Badge"
import SocialLink from "./common/SocialLink"
import theme from "styles/theme"

const { colors } = theme

const CARD_HEIGHT = "200px"
const MEDIA_QUERY_SMALL = "@media (max-width: 400px)"

export const Project = ({
  name,
  description,
  projectUrl,
  repositoryUrl,
  tags,
  publishedDate,
  imageUrl,
}) => (
  <Card p={0}>
    <Flex style={{ height: CARD_HEIGHT }}>
      <TextContainer>
        <span>
          <Title my={2} pb={1}>
            {name}
          </Title>
        </span>
        <Text width={[1]} style={{ overflow: "auto" }}>
          {description}
        </Text>
      </TextContainer>

      <ImageContainer>
        <ProjectImage src={imageUrl} alt={name} />
        <ProjectTag>
          <Flex style={{ justifyContent: "flex-end" }}>
            {repositoryUrl && (
              <Box
                mx={1}
                fontSize={5}
                onClick={() =>
                  trackCustomEvent({
                    category: "project",
                    action: "git-click",
                    label: name,
                  })
                }
              >
                <SocialLink
                  name="Check repository"
                  fontAwesomeIcon="github"
                  url={repositoryUrl}
                />
              </Box>
            )}
            <Box
              mx={1}
              fontSize={5}
              onClick={() =>
                trackCustomEvent({
                  category: "project",
                  action: "click",
                  label: name,
                })
              }
            >
              <SocialLink
                name="See project"
                fontAwesomeIcon="globe"
                url={projectUrl}
              />
            </Box>
          </Flex>
          <ImageText color="black" y="bottom" x="right" round>
            {tags.map((tag, i) => (
              <Badge key={i} color={"white"} bg={"#2ca8ff"}>
                {tag}
              </Badge>
            ))}
          </ImageText>
        </ProjectTag>
      </ImageContainer>
    </Flex>
  </Card>
)

export default Project

const Title = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  display: table;
  border-bottom: ${colors.primary} 5px solid;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  width: calc(100% - ${CARD_HEIGHT});
  ${MEDIA_QUERY_SMALL} {
    width: calc(100% - (${CARD_HEIGHT} / 2));
  }
`

const ImageContainer = styled.div`
  margin: auto;
  width: ${CARD_HEIGHT};
  ${MEDIA_QUERY_SMALL} {
    width: calc(${CARD_HEIGHT} / 2);
  }
`

const ProjectImage = styled.img`
  width: ${CARD_HEIGHT};
  height: ${CARD_HEIGHT};
  padding: 40px;
  margin: 0px;
  ${MEDIA_QUERY_SMALL} {
    height: calc(${CARD_HEIGHT} / 2);
    width: calc(${CARD_HEIGHT} / 2);
    margin-top: calc(${CARD_HEIGHT} / 4);
    padding: 10px;
  }
`

const ProjectTag = styled.div`
  position: relative;
  height: ${CARD_HEIGHT};
  top: calc(
    -${CARD_HEIGHT} - 3.5px
  ); /*don't know why I have to add 3.5px here ... */
  ${MEDIA_QUERY_SMALL} {
    top: calc(-${CARD_HEIGHT} - 3.5px + (${CARD_HEIGHT} / 4));
  }
`
