import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Section from "src/components/common/Section"
import { Project } from "src/components/Project"
import { CardContainer } from "src/components/common/Card"

const Prejects = () => {
  const data = useStaticQuery(graphql`
    {
      projects: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/projects/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              showcase
              external
              github
              image
              description
              tags
            }
            html
          }
        }
      }
    }
  `)
  const projects = data.projects.edges.filter(
    ({ node }) => node.frontmatter.showcase === "true"
  )
  console.log(data.projects)
  return (
    <Section.Container id="projects">
      <Section.Header name="Projects" icon="💻" label="notebook" />
      <CardContainer minWidth="350px">
        {projects.map(({ node }, i) => {
          const { frontmatter } = node
          const { github, external, title, tags, description, image } = frontmatter
          return (
            <Project
              key={i}
              name={title}
              description={description}
              projectUrl={external}
              repositoryUrl={github}
              tags={tags}
              imageUrl={image}
            />
          )
        })}
      </CardContainer>
    </Section.Container>
  )
}

export default Prejects
